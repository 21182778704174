import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step certificate fingerprint`}</strong>{` -- print the fingerprint of a certificate`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step certificate fingerprint <crt-file>
[--bundle] [--roots=<root-bundle>] [--servername=<servername>] 
[--format=<format>] [--sha1] [--insecure]
`}</code></pre>
    <h2>{`Description`}</h2>
    <p><strong parentName="p">{`step certificate fingerprint`}</strong>{` reads a certificate and prints to STDOUT the
certificate SHA256 of the raw certificate.`}</p>
    <p>{`If `}<inlineCode parentName="p">{`crt-file`}</inlineCode>{` contains multiple certificates (i.e., it is a certificate
"bundle") the fingerprint of the first certificate in the bundle will be
printed. Pass the --bundle option to print all fingerprints in the order in
which they appear in the bundle.`}</p>
    <h2>{`Positional arguments`}</h2>
    <p><inlineCode parentName="p">{`crt-file`}</inlineCode>{`
A certificate PEM file, usually the root certificate.`}</p>
    <h2>{`Options`}</h2>
    <p><strong parentName="p">{`--roots`}</strong>{`=`}<inlineCode parentName="p">{`roots`}</inlineCode>{`
Root certificate(s) that will be used to verify the
authenticity of the remote server.`}</p>
    <p><inlineCode parentName="p">{`roots`}</inlineCode>{` is a case-sensitive string and may be one of:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`file`}</strong>{`: Relative or full path to a file. All certificates in the file will be used for path validation.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`list of files`}</strong>{`: Comma-separated list of relative or full file paths. Every PEM encoded certificate from each file will be used for path validation.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`directory`}</strong>{`: Relative or full path to a directory. Every PEM encoded certificate from each file in the directory will be used for path validation.`}</p>
      </li>
    </ul>
    <p><strong parentName="p">{`--bundle`}</strong>{`
Print all fingerprints in the order in which they appear in the bundle.`}</p>
    <p><strong parentName="p">{`--insecure`}</strong>{`
Use an insecure client to retrieve a remote peer certificate. Useful for
debugging invalid certificates remotely.`}</p>
    <p><strong parentName="p">{`--servername`}</strong>{`=`}<inlineCode parentName="p">{`value`}</inlineCode>{`
TLS Server Name Indication that should be sent to request a specific certificate from the server.`}</p>
    <p><strong parentName="p">{`--format`}</strong>{`=`}<inlineCode parentName="p">{`format`}</inlineCode>{`
The `}<inlineCode parentName="p">{`format`}</inlineCode>{` of the fingerprint, it must be "hex", "base64", "base64-url", "base64-raw", "base64-url-raw" or "emoji".`}</p>
    <p><strong parentName="p">{`--sha1`}</strong>{`
Use the SHA-1 hash algorithm to hash the certificate. Requires `}<strong parentName="p">{`--insecure`}</strong>{` flag.`}</p>
    <h2>{`Examples`}</h2>
    <p>{`Get the fingerprint for a root certificate:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate fingerprint /path/to/root_ca.crt
0d7d3834cf187726cf331c40a31aa7ef6b29ba4df601416c9788f6ee01058cf3
`}</code></pre>
    <p>{`Get the fingerprint for a remote certificate:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate fingerprint https://smallstep.com
e2c4f12edfc1816cc610755d32e6f45d5678ba21ecda1693bb5b246e3c48c03d
`}</code></pre>
    <p>{`Get the fingerprints for a remote certificate with its intermediate:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate fingerprint --bundle https://smallstep.com
e2c4f12edfc1816cc610755d32e6f45d5678ba21ecda1693bb5b246e3c48c03d
25847d668eb4f04fdd40b12b6b0740c567da7d024308eb6c2c96fe41d9de218d
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      